<template>
  <b-card class="shadow-lg" no-body bg-variant="primary" >
    <b-card-body class="d-flex justify-content-between align-items-center p-1" >
      <i class="fas fa-info-circle" style="color:#efd179;font-size: 15px;position: absolute;right: 7px;top:7px" v-if="description != null" v-b-tooltip v-bind:title="$t(description)"></i>
      <div class="truncate">
        <div  class=" font-weight-bold" style="font-size: 17px;margin-bottom: 5px;color: black!important;font-weight: bold">{{$t(statisticTitle) }}</div>
        <slot name="content">
          <h2 style="font-size: 20px!important;" class="mb-25 font-weight-bolder">
            <slot   name="content-text">
              {{ statistic }}
            </slot>
          </h2>
        </slot>
        <slot name="footer">
          <span class="text-muted" style="font-size: 12px">{{$t(statisticDescription) }}</span>
        </slot>

      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon v-if="iconType === 'feather'" size="21" :icon="icon"/>
        <i v-if="iconType === 'fontawesome'" v-bind:class="{[icon]:true}" style="font-size: 21px;" v-bind:style="iconStyle"/>
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconType:{
      default:()=>"feather",
    },
    iconStyle:{

    },
    statistic: {
      type: [Number, String],
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticDescription:{
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'black',
    },
    description:{

    }
  },
}
</script>
