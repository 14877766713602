<template >
  <div v-if="null !=payments">
    <b-table
        ref="external-invoice-table"
        striped
        hover
        responsive
        v-bind:items="displayedPayments"
        v-bind:fields="fields"

        @row-clicked="goToContract"
    >
      <template #cell(clientId)="data">
        <div v-if="clients[data.value] != null">
          <b-link>{{ clients[data.value].firstname }}
            {{ clients[data.value].lastname }}
          </b-link>
        </div>
        <div v-else>
          <b-badge variant="warning">{{ $t('Anonymous') }}</b-badge>
        </div>
      </template>

      <template #cell(creationDate)="data">
        {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
      </template>

      <template #cell(status)="data" class="p-0">
        <b-badge :variant="statusVariants[data.value]">{{ data.value }}</b-badge>
      </template>
      <template #cell(method)="data" class="p-0">
        <b-badge variant="primary">{{ $t(data.value) }}</b-badge>
      </template>
      <template #cell(dynamicId)="data" class="p-0">
        <b-badge v-if="data.value!=null" variant="secondary">{{ getDateRange(data.value) }}</b-badge>
      </template>
      <template #cell(movedTransaction)="data" class="p-0">
        <b-badge v-if="data.value!=null" variant="secondary">{{ data.value? 'Überbuchung':'Direktbuchung' }}</b-badge>
      </template>
      <template #cell(amount)="data" class="p-0">
        <b-badge variant="primary">{{ convertToEURO(data.value) }}</b-badge>
      </template>
      <template #cell(platform)="data" class="p-0">
        <b-img width="80px" :src="getSource(data.value)"></b-img>
      </template>
    </b-table>

    <div  class="d-flex justify-content-between align-items-center mt-3">
      <div>
        Showing {{ displayedPayments.length }} of {{ payments.length }} items
      </div>
      <b-pagination
          v-model="page.currentPage"
          :total-rows="payments.length"
          :per-page="page.amount"
          align="center"
          size="md"
      ></b-pagination>
    </div>

  </div>
</template>

<script>

export default {
  name: 'external-invoices-table',
  data() {
    return {
      page: {
        amount: 10, // Number of rows per page
        currentPage: 1, // Current page
        total: 0, // Total number of rows (set to 0 initially)
      },
      loading: true,
      invoices: [],
      sort: {by: 'creationDate', direction: 'desc',},
      statusVariants:
          {
            "NEW": "primary",
            "CASH_OPEN": "primary",
            "PENDING": "warning",
            "SHIPPED": "success",
            "EXPIRED": "danger",
            "PAID": "success",
            "CASH_TAKEN": "danger",
            "CASH_IN": "success",
            "PAID_CASH": "success",
            "WAITING_FOR_PAYMENT": "warning",
            "PAYMENT_FAILED": "danger",
            "CANCELLED": "danger",
            "COMPLETED": "success"
          },

      //Listeners
      listeners: {}
    }
  },
  props: {
    payments: {
      required: true,
    },
    clients: {
      required: true,
    },
    relevantContracts: {
      required: true,
    },
    fieldsFilter: {
      default: () => [],
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
      }
    }
  },
  created() {
    //this.invoices=this.payments;
  },
  beforeDestroy() {
  },
  computed: {
    displayedPayments() {
      const startIndex = (this.page.currentPage - 1) * this.page.amount;
      const endIndex = startIndex + this.page.amount;

      return this.payments.slice(startIndex, endIndex);
    },
    fields() {
      let $this = this
      return [
        {
          key: 'clientId',
          label: $this.$t('client'),
          sortable: false
        },
        {
          key: 'dynamicId',
          label: $this.$t('checkInOut'),
          sortable: false
        },

        {
          key: 'amount',
          label: $this.$t('Total'),
          sortable: false
        },

        {
          key: 'method',
          label: $this.$t('method'),
          sortable: false
        },
  {
          key: 'movedTransaction',
          label: $this.$t('movedContract'),
          sortable: false
        },

        /*{
          key: 'status',
          label: this.$t('status'),
          sortable: true
        },*/
        {
          key: 'platform',
          label: $this.$t('Platform'),
          sortable: false
        },

        {
          key: 'dynamicId2',
          label: $this.$t('property.property'),
          sortable: true
        }
      ]

    },
  },
  methods: {
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    refresh() {
      this.loading = false;
    },
    goToContract(monetary) {
      this.$router.push(`/contract/view/${monetary.dynamicId}`)

    },
    onSort(data) {
      this.sort.by = data.sortBy
      this.sort.direction = data.sortDesc ? 'desc' : 'asc'
      this.refresh()
    },
    getDateRange(id) {

      var dates = this.relevantContracts[id];
      const checkIn = Object.keys(dates);
      const checkOut = Object.values(dates);

      return this.$moment(checkIn, "YYYY-MM-DD").format("DD.MM.YYYY") +' - '+this.$moment(checkOut, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    getSource(platform) {

      if (platform === 'Booking')
        return "https://treasurehome.at/uploads/images/booking.png"

      if (platform === 'Expedia')
        return "https://treasurehome.at/uploads/images/expedia.png"

      if (platform === 'Airbnb')
        return "https://treasurehome.at/uploads/images/airbnb.png"

      if (platform === 'Vrbo')
        return "https://treasurehome.at/uploads/images/vrbo.png"

      if (platform === 'Agoda')
        return "https://treasurehome.at/uploads/images/agoda.png"

      if (platform === 'Treasure Home')
        return "https://treasurehome.at/uploads/images/treasurehome.png"

      if (platform === 'Unknown')
        return "https://treasurehome.at/uploads/images/treasurehome.png"
    }
  }
}
</script>

<style scoped>

</style>
