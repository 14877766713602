<template>
  <div  class="mt-0 pt-0" style="font-weight: bold">
    <b-spinner v-if="!loaded" type="grow" label="Loading..." class="m-auto"></b-spinner>
    <b-card no-body class="shadow-lg rounded m-1" style="background-color: #ffffff!important;">

      <b-card-body>
        <b-col lg="12" sm="12">
          <b-card class="shadow-lg border" no-body>
            <b-card-header style="background-color: #c2aa8e" class="pb-50">
              <b-row no-gutters class="w-100">
                <b-col>
                  <h5 style="color: white">{{ $t("Filters") }}</h5>
                </b-col>
                <b-col>
                  <div class="d-flex justify-content-end">
                    <b-link style="color: white" @click="refresh()">
                      <feather-icon icon="RefreshCcwIcon" size="16"/>
                    </b-link>
                  </div>
                </b-col>
              </b-row>
            </b-card-header>
            <b-card-body class="border">
              <b-row class="mt-2">
                <b-col cols="12" md="12" lg="3">

                  <b-form-group :label="$t('Real estate')" label-for="realEstate-branch">
                    <real-estate-picker v-model="realEstateId"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12" lg="3">
                  <b-form-group :label="$t('Platform')" label-for="realEstate-type">
                    <v-select
                        v-model="platform"
                        :options="[
                      {value:'Booking',label:('Booking')},
                      {value:'Airbnb',label:('Airbnb')},
                      {value:'Treasure Home',label:('Treasure Home')},
                      {value:'Expedia',label:('Expedia')},
                      {value:'Vrbo',label:('Vrbo')},
                      {value:'Agoda',label:('Agoda')},
                      {value:null,label:('Alle Plattformen')}
                      ]"
                        label="label"
                        :reduce="(status) => status.value"
                        class="w-100"
                        :clearable="false"


                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" lg="3">
                  <b-form-group :label="$t('Month')" label-for="type">
                    <v-select
                        v-model="month"
                        :options="months"
                        label="label"
                        :reduce="(status) => status.value"
                        class="w-100"
                        :clearable="false"


                    />
                  </b-form-group>
                </b-col>
                  <b-col cols="12" md="12" lg="3">
                  <b-form-group :label="$t('Year')" label-for="type">
                    <v-select
                        v-model="year"
                        :options="years"
                        label="label"
                        :reduce="(status) => status.value"
                        class="w-100"
                        :clearable="false"

                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-row class="product-statistics p-2">
          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="FileIcon" color="success"
                                       :statistic="activeContracts"
                                       :statistic-title="$t('Running contracts')"/>
          </b-col>

          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="FileIcon" color="warning"
                                       :statistic="pendingContracts"
                                       :statistic-title="$t('Pending contracts')"/>
          </b-col>
          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="FileIcon" color="danger"
                                       :statistic="completedContracts"
                                       :statistic-title="$t('Completed contracts')"/>
          </b-col>


<!--          <b-col lg="2" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="CreditCardIcon"
                                       :statistic="paymentCounts"
                                       :statistic-title="'Payments'"/>
          </b-col>-->
          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="ShoppingBagIcon"
                                       color="success"
                                       :statistic=" convertToEURO(totalPaymentsIntern+totalPaymentsExtern)"

                                       :statistic-title="'Total income'">
            </statistic-card-horizontal>
          </b-col>
          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="DollarSignIcon"
                                       color="secondary"
                                       :statistic=" convertToEURO(totalPaymentsExtern)"

                                       :statistic-title="'External platforms incomes'">
            </statistic-card-horizontal>
          </b-col>
          <b-col lg="4" sm="4">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="DollarSignIcon"
                                       color="primary"
                                       :statistic=" convertToEURO(totalPaymentsIntern)"

                                       :statistic-title="'Treasure Home incomes'">
            </statistic-card-horizontal>
          </b-col>

          <b-col lg="3" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="DollarSignIcon"
                                       color="danger"
                                       :statistic=" convertToEURO(expenses>0?expenses-(totalPaymentsExtern*0.15):0)"

                                       :statistic-title="'Expenses'">
            </statistic-card-horizontal>
          </b-col>
          <b-col lg="3" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="DollarSignIcon"
                                       color="danger"
                                       :statistic=" convertToEURO(totalPaymentsExtern*0.15)"

                                       :statistic-title="'Commission'">
            </statistic-card-horizontal>
          </b-col>
          <b-col lg="2" sm="12">
            <b-button style="height: 75%;width: 100%;font-weight: bold" variant="primary" @click="sendInvoiceRequest">Jetzt Rechnungen anfordern</b-button>
          </b-col>

          <b-col lg="4" sm="12">
            <statistic-card-horizontal style="background-color: #d6e9e8!important;" icon="DollarSignIcon"
                                       color="success"
                                       :statistic=" convertToEURO((totalPaymentsIntern+totalPaymentsExtern)-(expenses>0?expenses:(totalPaymentsExtern*0.15)))"

                                       :statistic-title="'Profit'">
            </statistic-card-horizontal>
          </b-col>
          <b-col lg="12" sm="12" v-if="chartLoaded">
            <div id="chart">
              <VueApexCharts type="bar" height="350" :options="chartOptions" :series="series"></VueApexCharts>
            </div>

          </b-col>
          <b-col lg="12" sm="12">
            <b-card no-body>
              <b-card-header style="background-color: #c2aa8e" class="pb-50">
                <b-row no-gutters class="w-100">
                  <b-col>
                    <h5 style="color: white">{{ $t("External Platform Payments") }}</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body class="p-0 w-100">
                <external-invoices-table :amount="9999"
                                         v-bind:payments="externalPayments"
                                         :clients="clients" :relevant-contracts="relevantContracts"/>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="12" sm="12">
            <b-card no-body>
              <b-card-header style="background-color: #c2aa8e" class="pb-50">
                <b-row no-gutters class="w-100">
                  <b-col>
                    <h5 style="color: white">{{ $t("Internal Payments") }}</h5>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body class="p-0 w-100">
                <invoices-table :amount="9999"
                                v-bind:payments="internalPayments" :relevant-contracts="internalMonetaryContracts"
                                :clients="clients"/>
              </b-card-body>
            </b-card>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@/views/monetary/invoice/StatisticCardHorizontal'
import InvoicesTable from '@/views/monetary/invoice/invoices-table'
import {mapActions} from "vuex";
import VueApexCharts from 'vue-apexcharts'
import RealEstatePicker from "@/components/picker/realEstatesPicker";
import ExternalInvoicesTable from "@/views/monetary/invoice/external-invoices-table";

export default {
  name: 'payments',
  // eslint-disable-next-line vue/no-unused-components
  components: {ExternalInvoicesTable, InvoicesTable, StatisticCardHorizontal, VueApexCharts, RealEstatePicker},
  data: () => ({
    loaded: false,
    statusVariants:
        {
          "NEW": "primary",
          "CASH_OPEN": "primary",
          "PENDING": "warning",
          "SHIPPED": "success",
          "EXPIRED": "danger",
          "PAID": "success",
          "CASH_TAKEN": "danger",
          "CASH_IN": "success",
          "PAID_CASH": "success",
          "WAITING_FOR_PAYMENT": "warning",
          "PAYMENT_FAILED": "danger",
          "CANCELLED": "danger",
          "COMPLETED": "success"
        },

    dateFilter: {},
    months: [],
    years: [],
    realEstateId: null,
    platform: null,
    dateFilterOpen: false,
    internalPayments: null,
    externalPayments: null,
    relevantContracts: null,
    internalMonetaryContracts: null,
    year: null,
    month: null,
    totalPayments: 0.0,
    expenses: 0.0,
    totalPaymentsExtern: 0.0,
    totalPaymentsIntern: 0.0,
    paymentCounts: 0,
    activeContracts: 0,
    completedContracts: 0,
    pendingContracts: 0,
    soldProducts: 0,
    orderStats: {},
    chartExternal: {},
    clients: null,
    series: [{
      name: 'PRODUCT A',
      data: [44, 55, 41, 67, 22, 43]
    }, {
      name: 'PRODUCT B',
      data: [13, 23, 20, 8, 13, 27]
    }, {
      name: 'PRODUCT C',
      data: [11, 17, 15, 15, 21, 14]
    }, {
      name: 'PRODUCT D',
      data: [21, 7, 25, 13, 22, 8]
    }],
    chartLoaded: false,
    chartOptions: {
      colors:['#003990', '#ff5258', '#ffc94c','#C2aa8e', '#dfe6e9', '#00cec9'],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return "€" + val.toFixed(2);
        }
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return "€" + val;
          }
        }
      },
      xaxis: {
        type: 'datetime',
        categories: ['01/01/2011 GMT', '02/01/2011 GMT', '03/01/2011 GMT', '04/01/2011 GMT',
          '05/01/2011 GMT', '06/01/2011 GMT'
        ],
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },

  }),
  watch: {
    realEstateId: function () {
      this.refresh()
    },
    platform: function () {
      this.refresh()
    },
    year: function () {
      this.refresh()
    },
    month: function () {
      this.refresh()
    }
  },
  created() {

    let currentDate = new Date();
    this.year= currentDate.getFullYear();
    this.refresh()
  },
  mounted() {
    this.getMonthOptions();

  },

  computed: {
    dateShortcuts() {
      return [
        {
          key: 'thisWeek',
          label: 'This week',
          value: 'isoWeek'
        },
        {
          key: 'lastWeek',
          label: 'Last week',
          value: '-isoWeek'
        },
        {
          key: 'last7Days',
          label: 'Last 7 days',
          value: 7
        },
        {
          key: 'last30Days',
          label: 'Last 30 days',
          value: 30
        },
        {
          key: 'thisMonth',
          label: 'This month',
          value: 'month'
        },
        {
          key: 'lastMonth',
          label: 'Last month',
          value: '-month'
        },
        {
          key: 'thisYear',
          label: 'This year',
          value: 'year'
        },
        {
          key: 'lastYear',
          label: 'Last year',
          value: '-year'
        }
      ]
    },
    dateFilterStr() {
      if (this.dateFilter.start == null) {
        return this.$t('Today')
      }
      let shortcut = this.dateShortcuts.find(s => this.dateFilter.shortcut === s.value)
      if (shortcut != null) {
        return shortcut.label
      }

      let str = this.$moment(this.dateFilter.start)
          .format('l')
      if (this.dateFilter.end == null) {
        return str
      }

      return str + ' - ' + this.$moment(this.dateFilter.end)
          .format('l')
    },

  },
  methods: {
    ...mapActions('monetary', ['getDashboard','requestInvoice']),
    sendInvoiceRequest(){
      let dashboardFilter = {
        month: this.month,
        year: this.year,
        platform: this.platform,
        propertyId: this.realEstateId,
      };
      this.requestInvoice({dashboardFilter: dashboardFilter});


    },
    getMonthOptions() {
      const monthObj = {
        value: null,
        label: this.$t('All Months')
      };
      this.months.push(monthObj);

      for (let i = 0; i < 12; i++) {
        const monthObj = {
          value: i+1,
          label: new Date(0, i).toLocaleString('default', {month: 'long'})
        };
        this.months.push(monthObj);

      }

      const startYear = 2020;
      const currentDate = new Date();
      const endYear = currentDate.getFullYear()+2;


      for (let year = startYear; year <= endYear; year++) {
        const yearObj = {
          value: year,
          label: year
        };
        this.years.push(yearObj);
      }




    },
    onValueUpdated() {
      console.log('value updated')
      this.refresh()
    },
    refresh() {
      let dashboardFilter = {
        month: this.month,
        year: this.year,
        platform: this.platform,
        propertyId: this.realEstateId,
      };

      this.getDashboard({dashboardFilter: dashboardFilter})
          .then(this.onDashboardReceived)
    },

    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    onDashboardReceived(ans) {

      this.activeContracts = ans["active"] || 0;
      this.completedContracts = ans["completed"] || 0;
      this.pendingContracts = ans["pending"] || 0;
      this.totalPaymentsExtern = ans["totalPaymentsExtern"] || 0.0;
      this.totalPaymentsIntern = ans["totalPaymentsIntern"] || 0.0;

      /*relevant contracts for extern and internalmonetarycontracts for intern*/
      this.relevantContracts = ans["relevantContracts"] || null;
      this.internalMonetaryContracts = ans["internalMonetaryContracts"] || null;

      this.totalPayments = ans["totalPayments"] || 0.0;
      this.payments = ans["payments"] || null;

      this.chartExternal = ans["chartExternal"] || null;
      this.externalPayments = ans["externalPayments"] || null;
      this.internalPayments = ans["internalPayments"] || null;
      this.paymentCounts = ans["paymentCounts"] || 0;
      this.expenses = ans["expenses"] || 0;
      if (this.externalPayments != null && this.externalPayments.length > 0) {

        if (this.paymentCounts == null) {

          this.paymentCounts = this.externalPayments.size
        } else {
          this.paymentCounts = this.paymentCounts + this.externalPayments.length
        }

      }

      if (ans["clients"] != null)
        this.clients = ans["clients"].toObject('id');
      this.loaded = true;
      this.loadChart();
    },
    loadChart() {
      if (this.chartExternal == null || this.chartExternal.length === 0)
        return;

      this.setMonthsInChart();

      // eslint-disable-next-line no-unused-vars
      let dateMap = this.getPast6MonthsMap();

      this.series = [
        {name: "Booking", data: [0, 0, 0, 0, 0, 0, 0]},
        {name: "Airbnb", data: [0, 0, 0, 0, 0, 0, 0]},
        {name: "Expedia", data: [0, 0, 0, 0, 0, 0, 0]},
        {name: "Treasure Home", data: [0, 0, 0, 0, 0, 0, 0]},
        {name: "Vrbo", data: [0, 0, 0, 0, 0, 0, 0]},
        {name: "Agoda", data: [0, 0, 0, 0, 0, 0, 0]}
      ]

      for (const key in this.chartExternal) {
        for (const date in this.chartExternal[key]) {
          // Log the key and the current series array to console
          console.log('Key:', key);
          console.log('Series:', this.series);

          let seriesObj = this.series.find(obj => obj.name === key);
          if (seriesObj) {
            seriesObj.data[dateMap.get(date)] = this.chartExternal[key][date];
          } else {
            console.error('No matching object found for key: ' + key);
          }
        }
      }

      this.chartLoaded = true;
    },


    getPast6MonthsMap() {
      const monthsMap = new Map();
      const today = new Date();
      let sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));

      // Loop through each month starting from 7 months ago until now
      for (let i = 0; i < 7; i++) {
        // Format the month as 'MM/DD/YYYY GMT' where the day is always 01
        const formattedMonth = `${(sixMonthsAgo.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${sixMonthsAgo.getFullYear()}`;

        // Add the formatted month to the map
        monthsMap.set(formattedMonth,i);

        // Move to the next month
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() + 1);
      }

      return monthsMap;


    },
    setMonthsInChart() {

      const months = [];
      const today = new Date();
      const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));

      const nextMonth = new Date()
      // Loop through each month starting from 6 months ago until now
      while (sixMonthsAgo <= nextMonth) {
        // Format the month as 'MM/DD/YYYY GMT' where the day is always 01
        const formattedMonth = `${(sixMonthsAgo.getMonth() + 1)
            .toString()
            .padStart(2, '0')}/01/${sixMonthsAgo.getFullYear()} GMT`;
        months.push(formattedMonth);

        // Move to the next month
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() + 1);
      }
/*
      console.log(months)
*/
      this.chartOptions.xaxis.categories = months;

    }
  }
}
</script>

<style scoped>
.m-auto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
